import "swiper/css";
import "swiper/css/navigation";
import "../styles/swiper-custom.css";
import "../styles/globals.css";
import "../styles/animations.css";
import "../styles/ticker.css";
import "../styles/youtube-embed.css";
import "focus-visible";
import "../styles/transition-item.css";
import "../styles/blog-list.css";
import "../styles/dashed-border-box.css";
import "../styles/spinner.css";
import "../styles/button-loading.css";

import { ApolloProvider } from "@apollo/client";
import AppContextProvider from "../components/AppContext";
import CartContextProvider from "../components/CartContext";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "styled-components";
import ViewportContextProvider from "../components/ViewportContext";
import client from "../core/apollo-client";
import smoothscroll from "smoothscroll-polyfill";
import { theme } from "../core/theme";
import { useEffect } from "react";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      TagManager.initialize({ gtmId: "GTM-KG22NH8" });
    }
    smoothscroll.polyfill();
    history.scrollRestoration = "manual";
  }, []);

  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <ViewportContextProvider>
          <CartContextProvider>
            <ThemeProvider theme={theme}>
              <Component {...pageProps} />
            </ThemeProvider>
          </CartContextProvider>
        </ViewportContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  );
}

export default MyApp;
