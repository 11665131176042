import { ADD_TO_CART, REMOVE_FROM_CART } from "../core/api/cart-queries";
import React, { createContext, useContext, useEffect, useState } from "react";
import { createCart, loadCart } from "../core/api/cart";
import * as Sentry from "@sentry/browser";

import client from "../core/apollo-client";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";

export const CartContext = createContext();
export const useCartContext = () => useContext(CartContext);

function CartContextProvider({ children }) {
  const router = useRouter();
  const [cartOpen, setCartOpen] = useState(false);
  const [cart, setCart] = useState({ id: null, lines: [] });
  const [cartError, setCartError] = useState(null);

  useEffect(() => {
    setCartOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    if (cartOpen) {
      document.body.classList.add("overlay-open");
    } else {
      document.body.classList.remove("overlay-open");
    }
  }, [cartOpen]);

  useEffect(() => {
    window.resetCart = () => {
      window.localStorage.removeItem("reskinned:shopify:cart_id");
    };

    async function getOrCreateCart() {
      const cartId = window.localStorage
        ? window.localStorage.getItem("reskinned:shopify:cart_id")
        : null;

      if (cartId) {
        const existingCart = await loadCart(cartId);

        if (existingCart) {
          setCart({
            id: cartId,
            checkoutUrl: existingCart.checkoutUrl,
            estimatedCost: existingCart.estimatedCost,
            lines: existingCart.lines.edges,
          });
          return;
        } else {
          // If the cart could not be retrieved, remove this stale cartId from local
          // storage then continue to make new cart
          window.resetCart();
        }
      }
      const newCart = await createCart();

      setCart({
        id: newCart.id,
        checkoutUrl: newCart.checkoutUrl,
        estimatedCost: null,
        lines: [],
      });

      window.localStorage.setItem("reskinned:shopify:cart_id", newCart.id);
    }

    getOrCreateCart();
  }, []);

  const [addToCart, { data, loading: addToCartLoading, error }] = useMutation(
    ADD_TO_CART,
    {
      client,
      onError: (error) => {
        if (process.env.NODE_ENV !== "development") {
          Sentry.captureMessage(
            `Problem adding line item to cart: ${error.message}`
          );
          console.log(error.message);
        }
      },
      onCompleted: (data) => {
        if (data.cartLinesAdd.userErrors.length === 0) {
          setCartOpen(true);
          const cartCopy = Object.assign({}, cart);
          cartCopy["lines"] = data.cartLinesAdd.cart.lines.edges;
          cartCopy["estimatedCost"] = data.cartLinesAdd.cart.estimatedCost;
          setCart(cartCopy);
        } else {
          console.log(data.cartLinesAdd.userErrors);
          setCartError(true);
          if (process.env.NODE_ENV !== "development") {
            Sentry.captureMessage(
              `Problem adding line item to cart: ${JSON.stringify(
                data.cartLinesAdd.userErrors
              )}`
            );
          }
        }
      },
    }
  );

  const [
    removeFromCart,
    {
      data: removeFromCartData,
      loading: removeFromCartLoading,
      error: removeFromCartError,
    },
  ] = useMutation(REMOVE_FROM_CART, {
    client,
    onCompleted: (data) => {
      const cartCopy = Object.assign({}, cart);
      cartCopy["lines"] = data.cartLinesRemove.cart.lines.edges;
      cartCopy["estimatedCost"] = data.cartLinesRemove.cart.estimatedCost;
      setCart(cartCopy);
    },
  });

  function checkout() {
    window.location = cart.checkoutUrl;
  }

  return (
    <CartContext.Provider
      value={{
        cartOpen,
        setCartOpen,
        cart,
        addToCart,
        addToCartLoading,
        removeFromCart,
        removeFromCartLoading,
        checkout,
        cartError,
        setCartError,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContextProvider;
