import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";

import getConfig from "./config.js";

const NEXT_PUBLIC_SHOPIFY_STORE_NAME =
  process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME;

const NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN;

if (!NEXT_PUBLIC_SHOPIFY_STORE_NAME || !NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN) {
  throw new Error(
    "You must set the NEXT_PUBLIC_SHOPIFY_STORE_NAME and NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN env variables."
  );
}

const shopifyHttpLink = new HttpLink({
  uri: `https://${NEXT_PUBLIC_SHOPIFY_STORE_NAME}.myshopify.com/api/2023-07/graphql.json`,
});

const wagtailHttpLink = new HttpLink({
  uri: `${getConfig("CONTENT_API_BASE")}/api/graphql/`,
});

const shopifyAuthMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-Shopify-Storefront-Access-Token": NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Filter: {
        keyFields: ["values"],
      },
    },
  }),
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "wagtail",
    wagtailHttpLink, // <= apollo will send to this if clientName is "wagtail"
    concat(shopifyAuthMiddleware, shopifyHttpLink) // <= otherwise will send to this
  ),
});

export default client;
